import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'GBEN-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x4558E53328cDdD5877b7348702c991F521aa35C0',
    },
    tokenSymbol: 'GBEN',
    tokenAddresses: {
      97: '',
      56: '0x8173dDa13Fd405e5BcA84Bd7F64e58cAF4810A32',
    },
    dex: 0,
    tag: "nofee",
    priority: 500,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'GBEN-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xeb989b5026F8FFA7EaD82b1ca230faE1C31AEcAF',
    },
    tokenSymbol: 'GBEN',
    tokenAddresses: {
      97: '',
      56: '0x8173dDa13Fd405e5BcA84Bd7F64e58cAF4810A32',
    },
    dex: 0,
    tag: "nofee",
    priority: 500,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'BEN-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xF114543d2441Fe2D759598c337d22Bd69A994bD2',
    },
    tokenSymbol: 'BEN',
    tokenAddresses: {
      97: '',
      56: '0x8eE4924BD493109337D839C23f628e75Ef5f1C4D',
    },
    dex: 0,
    tag: "nofee",
    priority: 501,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'BEN-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x3cEBEbC6F06b08bF1E8af91B4D244BA7f97967dC',
    },
    tokenSymbol: 'BEN',
    tokenAddresses: {
      97: '',
      56: '0x8eE4924BD493109337D839C23f628e75Ef5f1C4D',
    },
    dex: 0,
    tag: "nofee",
    priority: 501,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 4,
    risk: 4,
    lpSymbol: 'DOGE-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xac109C8025F272414fd9e2faA805a583708A017f',
    },
    tokenSymbol: 'DOGE',
    tokenAddresses: {
      97: '',
      56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    },
    dex: 0,
    tag: "core",
    priority: 1,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 5,
    risk: 4,
    lpSymbol: 'DOGE-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xE27859308ae2424506D1ac7BF5bcb92D6a73e211',
    },
    tokenSymbol: 'DOGE',
    tokenAddresses: {
      97: '',
      56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    },
    dex: 0,
    tag: "core",
    priority: 1,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 6,
    risk: 3,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    dex: 0,
    tag: "core",
    priority: 1,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 7,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'GBEN',
    lpAddresses: {
      97: '',
      56: '0xf5325CbE15d2e5f47d6938cE3e3e853808D137AF', // GBEN-BUSD LP
    },
    tokenSymbol: 'GBEN',
    tokenAddresses: {
      97: '',
      56: '0x8173dDa13Fd405e5BcA84Bd7F64e58cAF4810A32',
    },
    dex: 1,
    tag: "nofee",
    priority: 9999,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 8,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'BEN',
    lpAddresses: {
      97: '',
      56: '0x3cEBEbC6F06b08bF1E8af91B4D244BA7f97967dC', // BEN-BUSD LP
    },
    tokenSymbol: 'BEN',
    tokenAddresses: {
      97: '',
      56: '0x8eE4924BD493109337D839C23f628e75Ef5f1C4D',
    },
    dex: 0,
    tag: "nofee",
    priority: 1000,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 9,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'DOGE',
    lpAddresses: {
      97: '',
      56: '0xE27859308ae2424506D1ac7BF5bcb92D6a73e211', // DOGE-BUSD LP
    },
    tokenSymbol: 'DOGE',
    tokenAddresses: {
      97: '',
      56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    },
    dex: 1,
    tag: "friend",
    priority: 100,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 10,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WBNB',
    lpAddresses: {
      97: '',
      56: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f', // BNB-BUSD LP
    },
    tokenSymbol: 'WBNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    dex: 1,
    tag: "core",
    priority: 50,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 11,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'BUSD',
    lpAddresses: {
      97: '',
      56: '0xf5325CbE15d2e5f47d6938cE3e3e853808D137AF', // GBEN-BUSD LP (BUSD-BUSD will ignore)
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '',
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    },
    dex: 1,
    tag: "core",
    priority: 10,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 12,
    risk: 3,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xc8Cf60A9ea33b156a58d5002c8BEeAFf6d34236c',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    dex: 1,
    tag: "core",
    priority: 101,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 13,
    risk: 3,
    lpSymbol: 'BNB-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x9da5Fe6D575Aa838fC24D2943e46d2D537a31a05',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    dex: 1,
    tag: "core",
    priority: 101,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 14,
    risk: 3,
    lpSymbol: 'BCH-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xbAc314feB7F08064d7F7D16e3152C68014FA6f87',
    },
    tokenSymbol: 'BCH',
    tokenAddresses: {
      97: '',
      56: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
    },
    dex: 1,
    tag: "core",
    priority: 102,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 15,
    risk: 5,
    lpSymbol: 'WISB-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x92025aE5193C6CA703e89646e41A7E917ba6C767',
    },
    tokenSymbol: 'WISB',
    tokenAddresses: {
      97: '',
      56: '0x4f491d389A5bF7C56bd1e4d8aF2280fD217C8543',
    },
    dex: 1,
    tag: "partner",
    priority: 103,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 16,
    risk: 2,
    lpSymbol: 'BTCB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x17E666bF65B74590FA61aD47C40C99472395437D',
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '',
      56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    },
    dex: 1,
    tag: "core",
    priority: 104,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 17,
    risk: 5,
    lpSymbol: 'GBEN-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x47Cdc99BC15951fA65D9b0a078d571aFBC332A9a',
    },
    tokenSymbol: 'GBEN',
    tokenAddresses: {
      97: '',
      56: '0x8173dDa13Fd405e5BcA84Bd7F64e58cAF4810A32',
    },
    dex: 1,
    tag: "nofee",
    priority: 9999,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 18,
    risk: 5,
    lpSymbol: 'GBEN-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xf5325CbE15d2e5f47d6938cE3e3e853808D137AF',
    },
    tokenSymbol: 'GBEN',
    tokenAddresses: {
      97: '',
      56: '0x8173dDa13Fd405e5BcA84Bd7F64e58cAF4810A32',
    },
    dex: 1,
    tag: "nofee",
    priority: 9999,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 19,
    risk: 5,
    lpSymbol: 'WISB-GBEN LP',
    lpAddresses: {
      97: '',
      56: '0x9455f22B218B01Cbe699149c04DebE138223DBbe',
    },
    tokenSymbol: 'WISB',
    tokenAddresses: {
      97: '',
      56: '0x4f491d389A5bF7C56bd1e4d8aF2280fD217C8543',
    },
    dex: 1,
    tag: "partner",
    priority: 10,
    quoteTokenSymbol: QuoteToken.GBEN,
    quoteTokenAdresses: contracts.gben,
  },
  {
    pid: 20,
    risk: 3,
    lpSymbol: 'BCH-BTCB LP',
    lpAddresses: {
      97: '',
      56: '0x94Cb89DE8BC27ce1611b5737d201497946c321E5',
    },
    tokenSymbol: 'BCH',
    tokenAddresses: {
      97: '',
      56: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
    },
    dex: 1,
    tag: "core",
    priority: 105,
    quoteTokenSymbol: QuoteToken.BTCB,
    quoteTokenAdresses: contracts.btcb,
  },
  {
    pid: 21,
    risk: 4,
    lpSymbol: 'DOGE-BTCB LP',
    lpAddresses: {
      97: '',
      56: '0x79C1bCf88Ac453902cB73e3a9930C92AfaD0966D',
    },
    tokenSymbol: 'DOGE',
    tokenAddresses: {
      97: '',
      56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    },
    dex: 1,
    tag: "core",
    priority: 106,
    quoteTokenSymbol: QuoteToken.BTCB,
    quoteTokenAdresses: contracts.btcb,
  },
  {
    pid: 22,
    risk: 5,
    lpSymbol: 'DOGE-GBEN LP',
    lpAddresses: {
      97: '',
      56: '0xb71a164DD9211F849F7f6b22151AeEF82382dbf1',
    },
    tokenSymbol: 'DOGE',
    tokenAddresses: {
      97: '',
      56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    },
    dex: 1,
    tag: "friend",
    priority: 480,
    quoteTokenSymbol: QuoteToken.GBEN,
    quoteTokenAdresses: contracts.gben,
  },
  {
    pid: 23,
    risk: 5,
    lpSymbol: 'SHIB-GBEN LP',
    lpAddresses: {
      97: '',
      56: '0xE83266710C2BD08b29D9840075f5a80e05f81dAa',
    },
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      97: '',
      56: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
    },
    dex: 1,
    tag: "friend",
    priority: 480,
    quoteTokenSymbol: QuoteToken.GBEN,
    quoteTokenAdresses: contracts.gben,
  },
  {
    pid: 24,
    risk: 5,
    lpSymbol: 'SHIB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xBe7e05Cbcfd1240CE9521232F7e4FAe89Ae4c383',
    },
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      97: '',
      56: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
    },
    dex: 1,
    tag: "core",
    priority: 107,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 25,
    risk: 5,
    lpSymbol: 'SHIB-ETH LP',
    lpAddresses: {
      97: '',
      56: '0xce7EBEe972325Da6576CC7B97BaB319ac4E9006A',
    },
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      97: '',
      56: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
    },
    dex: 1,
    tag: "core",
    priority: 107,
    quoteTokenSymbol: QuoteToken.ETH,
    quoteTokenAdresses: contracts.eth,
  },
  {
    pid: 26,
    risk: 2,
    lpSymbol: 'ETH-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x67FF71B63A558E3aC13A4503b89372d4eFBC74e8',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    dex: 1,
    tag: "core",
    priority: 107,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 27,
    risk: 3,
    lpSymbol: 'ETH-BTCB LP',
    lpAddresses: {
      97: '',
      56: '0xFAE4F592B5109c8D2A7d782A8D51f90ECb372B5f',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    dex: 1,
    tag: "core",
    priority: 107,
    quoteTokenSymbol: QuoteToken.BTCB,
    quoteTokenAdresses: contracts.btcb,
  },
  {
    pid: 28,
    risk: 4,
    lpSymbol: 'DOGE-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x81Fd675613F3F7053673763816bf23bdEa242646',
    },
    tokenSymbol: 'DOGE',
    tokenAddresses: {
      97: '',
      56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    },
    dex: 1,
    tag: "core",
    priority: 108,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 29,
    risk: 4,
    lpSymbol: 'TWT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xb72F85E1934E9371EAfB663C1A6c8c02B9aEf6bc',
    },
    tokenSymbol: 'TWT',
    tokenAddresses: {
      97: '',
      56: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
    },
    dex: 1,
    tag: "core",
    priority: 108,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },  
  {
    pid: 30,
    risk: 4,
    lpSymbol: 'TWT-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x1825Be27d0aAacb60a50F3a31789298Ecd8FBa02',
    },
    tokenSymbol: 'TWT',
    tokenAddresses: {
      97: '',
      56: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
    },
    dex: 1,
    tag: "core",
    priority: 108,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 31,
    risk: 4,
    lpSymbol: 'BEN-GBEN LP',
    lpAddresses: {
      97: '',
      56: '0x355ca2fd67BEE9D17dC5645012De82ed6B1e5444',
    },
    tokenSymbol: 'BEN',
    tokenAddresses: {
      97: '',
      56: '0x8eE4924BD493109337D839C23f628e75Ef5f1C4D',
    },
    dex: 1,
    tag: "ecosystem",
    priority: 490,
    quoteTokenSymbol: QuoteToken.GBEN,
    quoteTokenAdresses: contracts.gben,
  },
  {
    pid: 32,
    risk: 4,
    isTokenOnly: true,
    lpSymbol: 'SHIB',
    lpAddresses: {
      97: '',
      56: '0xBe7e05Cbcfd1240CE9521232F7e4FAe89Ae4c383', // SHIB-BUSD LP
    },
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      97: '',
      56: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
    },
    dex: 1,
    tag: "friend",
    priority: 100,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 33,
    risk: 5,
    lpSymbol: 'BEN-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x5114869400676CF71B8b3474036C21365A12898c',
    },
    tokenSymbol: 'BEN',
    tokenAddresses: {
      97: '',
      56: '0x8eE4924BD493109337D839C23f628e75Ef5f1C4D',
    },
    dex: 1,
    tag: "nofee",
    priority: 495,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 34,
    risk: 5,
    lpSymbol: 'BEN-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x3EcC29889F3768c9120ebAf3828BADDB2E60AF91',
    },
    tokenSymbol: 'BEN',
    tokenAddresses: {
      97: '',
      56: '0x8eE4924BD493109337D839C23f628e75Ef5f1C4D',
    },
    dex: 1,
    tag: "nofee",
    priority: 495,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 35,
    risk: 5,
    lpSymbol: 'EBEN-GBEN LP',
    lpAddresses: {
      97: '',
      56: '0x59Efc73527e8304d3faEBF58594a25Fb7638E84A',
    },
    tokenSymbol: 'EBEN',
    tokenAddresses: {
      97: '',
      56: '0xbb036ccDe5feE48f011B9916646f3a341d7D490A',
    },
    dex: 1,
    tag: "ecosystem",
    priority: 490,
    quoteTokenSymbol: QuoteToken.GBEN,
    quoteTokenAdresses: contracts.gben,
  },
  {
    pid: 36,
    risk: 5,
    lpSymbol: 'GBEN-USDT LP',
    lpAddresses: {
      97: '',
      56: '0xF6628437Ad528FcaE38e11eBa78f2015214fd78d',
    },
    tokenSymbol: 'GBEN',
    tokenAddresses: {
      97: '',
      56: '0x8173dDa13Fd405e5BcA84Bd7F64e58cAF4810A32',
    },
    dex: 0,
    tag: "nofee",
    priority: 500,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 37,
    risk: 5,
    lpSymbol: 'GBEN-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x9F139EB8160A299035b02C01Ebb7A92BAeC20F89',
    },
    tokenSymbol: 'GBEN',
    tokenAddresses: {
      97: '',
      56: '0x8173dDa13Fd405e5BcA84Bd7F64e58cAF4810A32',
    },
    dex: 1,
    tag: "nofee",
    priority: 9999,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 38,
    risk: 5,
    lpSymbol: 'BEN-USDT LP',
    lpAddresses: {
      97: '',
      56: '0xD7CEE87f00277BA27466844a11abd956E9BECDb8',
    },
    tokenSymbol: 'BEN',
    tokenAddresses: {
      97: '',
      56: '0x8eE4924BD493109337D839C23f628e75Ef5f1C4D',
    },
    dex: 1,
    tag: "nofee",
    priority: 495,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
].sort((a,b)=>{
  return (b.priority||0) - (a.priority||0)
})

export default farms