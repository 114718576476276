import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@pancakeswap-libs/uikit'
import Page from './layout/Page'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Inner = styled.div`
  width: 64px;
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <Inner>
        <Spinner />
      </Inner>
    </Wrapper>
  )
}

export default PageLoader