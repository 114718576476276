export default {
  cake: {
    56: '0x8173dDa13Fd405e5BcA84Bd7F64e58cAF4810A32',
    97: '',
  },
  gben: {
    56: '0x8173dDa13Fd405e5BcA84Bd7F64e58cAF4810A32',
    97: '',
  },
  masterChef: {
    56: '0x03245d87295cd0783E1f10a2EA54F9E80B726aF8',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '0xea1FA5cb5d85148fF2f8a4bdD0a23de477843c86',
    97: '',
  },
  lotteryNFT: {
    56: '0xF5789f157a9987Da71266f8ef6Ee1F270Bd4aC4A',
    97: '',
  },
  temple: {
    56: '0x5e5C3AEC925Ae281DAFe8Ca5777E7Ab021De755c',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  usdt: {
    56: '0x55d398326f99059fF775485246999027B3197955',
    97: '',
  },
  btcb: {
    56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    97: '',
  },
  eth: {
    56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    97: '',
  },
}
