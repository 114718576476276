import { ethers } from 'ethers'
import addresses from 'config/constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID

export const isAddressMatch = (address1, address2) => {
  return address1 && address2 && address1.toLowerCase() === address2.toLowerCase();
};

export const getChecksumAddress = (value) => {
  try {
      return ethers.utils.getAddress(value.toLowerCase())
  } catch (err) {
      return false
  }
}

export const getCakeAddress = () => {
  return addresses.cake[chainId]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[chainId]
}
export const getWbnbAddress = () => {
  return addresses.wbnb[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}
export const getTempleAddress = () => {
  return addresses.temple[chainId]
}
